import { createAsyncThunk } from "@reduxjs/toolkit";
import USERAPI from "./api";
import { getCookie } from "cookies-next";

const doGetBooks = createAsyncThunk<any, any, any>(
  "user/doGetBooks",
  async (
    { search, subject_id, school_years, education_types, teacher_name, page },
    { rejectWithValue }
  ) => {
    try {
      const response = await USERAPI.getBooks(
        search,
        subject_id,
        school_years,
        education_types,
        teacher_name,
        page
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doBuyBook = createAsyncThunk<any, any, any>(
  "user/doBuyBook",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.buyBook(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doCheckInvoice = createAsyncThunk<any, any, any>(
  "user/doCheckInvoice",
  async ({ invoice_id }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.checkInvoice(invoice_id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// cart
const doGetCart = createAsyncThunk<any, any, any>(
  "admin/doGetCart",
  async ({ _ }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.getCart();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doAddToCart = createAsyncThunk<any, any, any>(
  "admin/doAddToCart",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.addToCart(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doUpdateCartItem = createAsyncThunk<any, any, any>(
  "admin/doUpdateCartItem",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.updateCartItem(id, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doDeleteCartItem = createAsyncThunk<any, any, any>(
  "admin/doDeleteCartItem",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.deleteCartItem(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetTeachers = createAsyncThunk<any, any, any>(
  "user/doGetTeachers",
  async ({ search, school_years, page }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.getTeachers(search, school_years, page);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doGetSingleTeachers = createAsyncThunk<any, any, any>(
  "user/doGetSingleTeachers",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.getSingleTeachers(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doGetExternalCodes = createAsyncThunk<any, any, any>(
  "user/doGetExternalCodes",
  async ({ search, school_years }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.getExternalCodes(search, school_years);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doBuyExternalCodes = createAsyncThunk<any, any, any>(
  "user/doBuyExternalCodes",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.buyExternalCodes(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetProfile = createAsyncThunk<any, any, any>(
  "user/doGetProfile",
  async ({}, { rejectWithValue }) => {
    try {
      const response = await USERAPI.getProfile();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doUpdateProfile = createAsyncThunk<any, any, any>(
  "user/doUpdateProfile",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.updateProfile(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doChangePassword = createAsyncThunk<any, any, any>(
  "user/doChangePassword",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.changePassword(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetHomeDashboard = createAsyncThunk<any, any, any>(
  "user/doGetHomeDashboard",
  async ({ queryName, queryValue }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.getHomeDashboard({
        queryName,
        queryValue,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetInvoices = createAsyncThunk<any, any, any>(
  "user/doGetInvoices",
  async ({ page }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.getInvoices(page);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetWallet = createAsyncThunk<any, any, any>(
  "user/doGetWallet",
  async ({ queryName, queryValue }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.getWallet({
        queryName,
        queryValue,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doChargeWallet = createAsyncThunk<any, any, any>(
  "user/doChargeWallet",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.chargeWallet(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetSubjects = createAsyncThunk<any, any, any>(
  "user/doGetSubjects",
  async ({ search, school_years, page }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.getSubjects(search, school_years, page);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetMySubjects = createAsyncThunk<any, any, any>(
  "user/doGetMySubjects",
  async ({}, { rejectWithValue }) => {
    try {
      const response = await USERAPI.getMySubjects();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doGetMyCourses = createAsyncThunk<any, any, any>(
  "user/doGetMyCourses",
  async ({}, { rejectWithValue }) => {
    try {
      const tokenTwo = getCookie("Education_User_token");
      if (tokenTwo) {
        const response = await USERAPI.getMyCourses();
        return response.data;
      } else {
        return null;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetMyCourse = createAsyncThunk<any, any, any>(
  "user/doGetMyCourse",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.getMyCourse(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetMyExams = createAsyncThunk<any, any, any>(
  "user/doGetMyExams",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.getMyExams(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetSingleBook = createAsyncThunk<any, any, any>(
  "user/doGetSingleBook",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.getSingleBook(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetSingleExternalCode = createAsyncThunk<any, any, any>(
  "user/doGetSingleExternalCode",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.getSingleExternalCode(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetCourses = createAsyncThunk<any, any, any>(
  "user/doGetCourses",
  async (
    { search, school_years, page, is_digital_book },
    { rejectWithValue }
  ) => {
    try {
      const response = await USERAPI.getCourses(
        search,
        school_years,
        page,
        is_digital_book
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetSingleCourse = createAsyncThunk<any, any, any>(
  "user/doGetSingleCourse",
  async ({ id, page, myComments }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.getSingleCourse(id, page, myComments);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doBuyCourse = createAsyncThunk<any, any, any>(
  "user/doBuyCourse",
  async ({ data, isFullExam }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.buyCourse(data, isFullExam);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doSubscribeWithTeacher = createAsyncThunk<any, any, any>(
  "user/doSubscribeWithTeacher",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.subscribeWithTeacher(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doGetVideoConfiguration = createAsyncThunk<any, any, any>(
  "user/doGetVideoConfiguration",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.getVideoConfiguration(id, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doUpdateVideoStatus = createAsyncThunk<any, any, any>(
  "user/doUpdateVideoStatus",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.updateVideoStatus(id, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doUpdateLessonStatus = createAsyncThunk<any, any, any>(
  "user/doUpdateLessonStatus",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.updateLessonStatus(id, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doStartExam = createAsyncThunk<any, any, any>(
  "user/doStartExam",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.startExam(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doSolveExam = createAsyncThunk<any, any, any>(
  "user/doSolveExam",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.solveExam(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doEndExam = createAsyncThunk<any, any, any>(
  "user/doEndExam",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.endExam(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// full exams
const doGetFullExams = createAsyncThunk<any, any, any>(
  "user/doGetFullExams",
  async ({ search, school_years }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.getFullExams(search, school_years);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doGetSingleFullExam = createAsyncThunk<any, any, any>(
  "user/doGetSingleFullExam",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.getSingleFullExam(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doGetMyFullExams = createAsyncThunk<any, any, any>(
  "user/doGetMyFullExams",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.getMyFullExams(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doStartFullExam = createAsyncThunk<any, any, any>(
  "user/doStartFullExam",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.startFullExam(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doSolveFullExam = createAsyncThunk<any, any, any>(
  "user/doSolveFullExam",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.solveFullExam(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doEndFullExam = createAsyncThunk<any, any, any>(
  "user/doEndFullExam",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.endFullExam(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetHome = createAsyncThunk<any, any, any>(
  "user/doGetHome",
  async (_, { rejectWithValue }) => {
    try {
      const response = await USERAPI.getHome();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// comments
const doGetCommentsReplies = createAsyncThunk<any, any, any>(
  "admin/doGetCommentsReplies",
  async ({ commentId, page }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.getCommentReplies(commentId, page);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doAddComment = createAsyncThunk<any, any, any>(
  "admin/doAddComment",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.addComment(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doUpdateComment = createAsyncThunk<any, any, any>(
  "admin/doUpdateComment",
  async ({ commentId, data }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.updateComment(commentId, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doDeleteComment = createAsyncThunk<any, any, any>(
  "admin/doDeleteComment",
  async ({ commentId }, { rejectWithValue }) => {
    try {
      const response = await USERAPI.deleteComment(commentId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const thunks = {
  doGetBooks,
  doBuyBook,
  doCheckInvoice,
  doGetCart,
  doAddToCart,
  doUpdateCartItem,
  doDeleteCartItem,
  doGetTeachers,
  doGetSingleTeachers,
  doGetExternalCodes,
  doBuyExternalCodes,
  doGetProfile,
  doUpdateProfile,
  doChangePassword,
  doGetHomeDashboard,
  doGetInvoices,
  doGetWallet,
  doChargeWallet,
  doGetSubjects,
  doGetMySubjects,
  doGetMyCourses,
  doGetSingleBook,
  doGetMyCourse,
  doGetMyExams,
  doGetSingleExternalCode,
  doGetCourses,
  doGetSingleCourse,
  doBuyCourse,
  doSubscribeWithTeacher,
  doGetVideoConfiguration,
  doUpdateVideoStatus,
  doUpdateLessonStatus,
  doSolveExam,
  doStartExam,
  doEndExam,
  doGetFullExams,
  doGetSingleFullExam,
  doGetMyFullExams,
  doStartFullExam,
  doSolveFullExam,
  doEndFullExam,
  doGetHome,
  // comments
  doGetCommentsReplies,
  doAddComment,
  doUpdateComment,
  doDeleteComment,
};

export default thunks;
