type TinitialState = {
  profile: any;
  home: any;
  permissions: any;
  myPermissions: any;
  subjects: any;
  singleSubject: any;
  teachers: any;
  singleTeacher: any;
  books: any;
  singleBook: any;
  externalCodes: any;
  singleExternalCode: any;
  internalCodes: any;
  singleInternalCode: any;
  moderators: any;
  singleModerator: any;
  students: any;
  singleStudent: any;
  studentExams: any;
  users: any;
  singleUser: any;
  courses: any;
  singleCourse: any;
  teacherChapters: any;
  singleFullExam: any;
  fullExams: any;
  videos: any;
  teacherVideos: any;
  singleVideo: any;
  adminLogged: {
    isLoggedIn: boolean;
    adminToken: string | null;
    adminData: {};
    role: string;
  };
  // Money
  moneyRequests: any;
  moneyRequest: any;
  // Finances
  finances: any;
  singleBookSalesInfo: any;
  singleTeacherFinanceInfo: any;
  singleUserChargeWalletInfo: any;
  singleCourseFinanceInfo: any;
  singleFullExamFinanceInfo: any;
  // chartByMonth
  chartByMonth: any;

  singleFullExamQuestion: any;
  orders: any;

  comments: any;
  courseUsers: any;
  courseLessonsAnalytics: any;
  courseLessonsStudentsAnalytics: any;
  courseTestsStudentsAnalytics: any;
  teacherLessons: any;
  teacherCourses: any;
  testBank: any;
  singleTestBank: any;
  doorTestBank: any;
  singleDoorTestBank: any;
  studentCourses: any;
  userSessions: any;
  lessonsDoor: any;
  levelsLesson: any;
  questions: any;
  digitalExams: any;
  singleDigitalExam: any;
  teacherBanks: any;
  doorsInBank: any;
  lessonsInDoor: any;
  levelsInLesson: any;
  teacherSchoolYears: any;
};

export const initialValue: TinitialState = {
  profile: {},
  home: {},
  permissions: [],
  myPermissions: [],
  subjects: [],
  singleSubject: {},
  teachers: [],
  singleTeacher: {},
  books: [],
  singleBook: {},
  externalCodes: [],
  singleExternalCode: {},
  internalCodes: [],
  singleInternalCode: {},
  moderators: [],
  singleModerator: {},
  students: {},
  singleStudent: {},
  studentExams: [],
  users: {},
  singleUser: {},
  courses: [],
  singleCourse: {},
  teacherChapters: [],
  singleFullExam: {},
  fullExams: [],
  videos: {},
  teacherVideos: [],
  singleVideo: {},
  adminLogged: {
    isLoggedIn: false,
    adminToken: "",
    adminData: {},
    role: "",
  },
  // Money
  moneyRequests: [],
  moneyRequest: {},
  // Finances
  finances: [],
  singleBookSalesInfo: [],
  singleTeacherFinanceInfo: {},
  singleUserChargeWalletInfo: [],
  singleCourseFinanceInfo: {},
  singleFullExamFinanceInfo: {},
  // ChartByMonth
  chartByMonth: {},

  singleFullExamQuestion: {},

  orders: {},
  comments: {},
  courseUsers: {},
  courseLessonsAnalytics: [],
  courseLessonsStudentsAnalytics: {},
  courseTestsStudentsAnalytics: {},
  teacherLessons: [],
  teacherCourses: [],
  testBank: [],
  singleTestBank: {},
  doorTestBank: [],
  singleDoorTestBank: {},
  studentCourses: [],
  userSessions: {},
  lessonsDoor: [],
  levelsLesson: [],
  questions: [],
  digitalExams: [],
  singleDigitalExam: {},
  teacherBanks: [],
  doorsInBank: [],
  lessonsInDoor: [],
  levelsInLesson: [],
  teacherSchoolYears: [],
};
