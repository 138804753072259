import api from "../_axios";

const LiveURL = "https://backend.eduvalu.com/api/v1";
const TestURL = "https://server.eduvalu.com/api/v1";
const LocalURL = "http://localhost:8000/api/v1";

const getCities = () => api.get(`${LiveURL}/cities`);
const getAreas = (cityid: number) => api.get(`${LiveURL}/areas/${cityid}`);
const getLanguages = () => api.get(`${LiveURL}/languages`);
const getMainEducationTypes = () => api.get(`${LiveURL}/type-educations`);
const getEducationTypes = (school_year_id: any) =>
  api.get(`${LiveURL}/education-types?school_year_id=${school_year_id}`);
const getSchoolYears = (mainEducationType?: number, teacherId?: number) =>
  api.get(
    `${LiveURL}/school-years?teacher_id=${
      teacherId ? teacherId : ""
    }&type_education_id=${mainEducationType ? mainEducationType : ""}`
  );
const getSchoolTypes = () => api.get(`${LiveURL}/school-types`);

const GENERALAPI = {
  getCities,
  getAreas,
  getLanguages,
  getMainEducationTypes,
  getEducationTypes,
  getSchoolYears,
  getSchoolTypes,
};
export default GENERALAPI;
