import { adminApi } from "../_axios";

interface UserParams {
  teachers?: string[];
  schoolYears?: string[];
  educationTypes?: string[];
  cities?: string[];
  school_types?: string[];
  name?: string;
  [key: string]: any;
}

const buildQueryString = (params: UserParams): string => {
  console.log(params);
  const param = Object.entries(params)
    .filter(([_, value]) => value != null && value !== "")
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return `${encodeURIComponent(key)}=${value
          .map(encodeURIComponent)
          .join(",")}`;
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");
  console.log(params);
  console.log(param);
  return param;
};

const getProfile = () => adminApi.get("/profile");
const updateProfile = (data: any) => adminApi.post("/profile", data);
const changePassword = (data: any) =>
  adminApi.post("/profile-change-password", data);

const adminLogin = (data: any) => adminApi.post("/login", data);
const getHome = ({
  queryName,
  queryValue,
}: {
  queryName?: string;
  queryValue?: string;
}) => adminApi.get(`/home?${queryName}=${queryValue}`);
const getPermissions = () => adminApi.get("/permissions");
const getMyPermissions = () => adminApi.get("/my-permissions");
const getSubjects = () => adminApi.get("/subjects");
const getSingleSubject = (id: number) => adminApi.get(`/subjects/${id}`);
const createSubject = (data: any) => adminApi.post("/subjects", data);
const updateSubject = (id: number, data: any) =>
  adminApi.post(`/subjects/${id}`, data);
const deleteSubject = (id: number) => adminApi.delete(`/subjects/${id}`);

const getTeachers = () => adminApi.get("/teachers");
const getSingleTeacher = (id: number) => adminApi.get(`/teachers/${id}`);
const createTeacher = (data: any) => adminApi.post("/teachers", data);
const updateTeacher = (id: number, data: any) =>
  adminApi.post(`/teachers/${id}`, data);
const deleteTeacher = (id: number) => adminApi.delete(`/teachers/${id}`);
const updateTeachersOrder = (data: any) =>
  adminApi.post(`/order-teachers/`, { teachers: data });

const getBooks = () => adminApi.get("/books");
const getSingleBook = (id: number) => adminApi.get(`/books/${id}`);
const createBook = (data: any) => adminApi.post("/books", data);
const updateBook = (id: number, data: any) =>
  adminApi.post(`/books/${id}`, data);
const deleteBook = (id: number) => adminApi.delete(`/books/${id}`);

const getExternalCodes = () => adminApi.get("/external-codes");
const getExternalCode = (id: number) => adminApi.get(`/external-codes/${id}`);
const createExternalCode = (data: any) =>
  adminApi.post("/external-codes", data);
const updateExternalCode = (id: number, data: any) =>
  adminApi.post(`/external-codes/${id}`, data);
const deleteExternalCode = (id: number) =>
  adminApi.delete(`/external-codes/${id}`);

const getInternalCodes = () => adminApi.get("/internal-codes");
const getInternalCode = (id: number) => adminApi.get(`/internal-codes/${id}`);
const createInternalCode = (data: any) =>
  adminApi.post("/internal-codes", data);
const updateInternalCode = (id: number, data: any) =>
  adminApi.post(`/internal-codes/${id}`, data);
const deleteInternalCode = (id: number) =>
  adminApi.delete(`/internal-codes/${id}`);

const getModerators = () => adminApi.get("/moderators");
const getSingleModerator = (id: number) => adminApi.get(`/moderators/${id}`);
const createModerator = (data: any) => adminApi.post("/moderators", data);
const updateModerator = (id: number, data: any) =>
  adminApi.post(`/moderators/${id}`, data);
const deleteModerator = (id: number) => adminApi.delete(`/moderators/${id}`);

const getStudents = (page: number, params: UserParams = {}) => {
  const queryString = buildQueryString(params);
  const pageParam = page ? `page=${page}` : "";
  const separator = pageParam && queryString ? "&" : "";

  return adminApi.get(`/students?${pageParam}${separator}${queryString}`);
};

const getSingleStudent = (id: number) => adminApi.get(`/students/${id}`);
const getStudentExams = (
  studentId: number,
  courseId: number,
  isFullExam?: boolean
) => {
  if (isFullExam) {
    return adminApi.get(`/students/${studentId}/full-exams/${courseId}`);
  } else {
    return adminApi.get(`/students/${studentId}/courses/${courseId}/exams`);
  }
};
const correctQuestion = (id: number, data: any, isFullExam?: boolean) => {
  if (isFullExam) {
    return adminApi.post(`/correct-full-exam-questions/${id}`, data);
  } else {
    return adminApi.post(`/correct-questions/${id}`, data);
  }
};

const getUsers = (page: number, params: UserParams = {}) => {
  const queryString = buildQueryString(params);
  const pageParam = page ? `page=${page}` : "";
  const separator = pageParam && queryString ? "&" : "";
  // console.log(queryString, "quertyString");

  return adminApi.get(`/users?${pageParam}${separator}${queryString}`);
};

const getSingleUser = (id: number) => adminApi.get(`/users/${id}`);
const createUser = (data: any) => adminApi.post("/users", data);
const updateUserStatus = (id: number, data: any) =>
  adminApi.post(`/update-user-status/${id}`, data);
const updateUser = (id: number, data: any) =>
  adminApi.post(`/users/${id}`, data);
const deleteUser = (id: number) => adminApi.delete(`/users/${id}`);

const getAllVideos = (
  teacherId: number | null,
  page: number | null,
  limit: number | null
) =>
  adminApi.get(
    `/videos?${teacherId ? `teacher_id=${teacherId}` : ""}&${
      page ? `page=${page}&` : ""
    }&${limit ? `page=${limit}&` : ""}&`
  );
const getTeacherVideos = (teacherId: number) =>
  adminApi.get(`/teacher-videos/${teacherId}`);
const getSingleVideo = (id: number) => adminApi.get(`/videos/${id}`);
const createVideo = (data: any) => adminApi.post("/videos", data);
const updateVideo = (id: number, data: any) =>
  adminApi.post(`/videos/${id}`, data);
const deleteVideo = (id: number) => adminApi.delete(`/videos/${id}`);
// courses
const getCourses = (
  subject?: number,
  teacher?: number,
  is_digital_book?: number
) => {
  // Create base URL
  let url = "/courses";

  // Initialize an array to hold query parameters
  const queryParams: string[] = [];

  // Add parameters to the query array if they exist
  if (typeof is_digital_book !== "undefined") {
    queryParams.push(`is_digital_book=${is_digital_book}`);
  }
  if (subject) {
    queryParams.push(`subject=${subject}`);
  }
  if (teacher) {
    queryParams.push(`teacher=${teacher}`);
  }

  // If there are query parameters, join them with '&' and append to the URL
  if (queryParams.length > 0) {
    url += `?${queryParams.join("&")}`;
  }

  // Return the API request
  return adminApi.get(url);
};

const getSingleCourse = (id: number) => adminApi.get(`/courses/${id}`);
const createCourse = (data: any) => adminApi.post("/courses", data);
const updateCourse = (id: number, data: any) =>
  adminApi.post(`/courses/${id}`, data);
const deleteCourse = (id: number) => adminApi.delete(`/courses/${id}`);
// courses - chapter
const createCourseChapter = (id: number, data: any) =>
  adminApi.post(`/courses/${id}/chapters`, data);
const updateCourseChapter = (courseId: number, chapterId: number, data: any) =>
  adminApi.post(`/courses/${courseId}/chapters/${chapterId}`, data);
const deleteCourseChapter = (courseId: number, chapterId: number) =>
  adminApi.delete(`/courses/${courseId}/chapters/${chapterId}`);

// courses - chapter - lessons
const createCourseLesson = (id: number, data: any) =>
  adminApi.post(`/chapters/${id}/lessons`, data);
const deleteCourseLesson = (chapterId: number, lessonId: number) =>
  adminApi.delete(`/chapters/${chapterId}/lessons/${lessonId}`);
const updateLesson = (chapterId: number, lessonId: number, data: any) =>
  adminApi.post(`/chapters/${chapterId}/lessons/${lessonId}`, data);
const updateLessonsOrder = (chapterId: number, data: any) =>
  adminApi.post(`/chapters/${chapterId}/order-lessons/`, data);
const getTeacherChapters = (id: any) =>
  adminApi.get(`/get-teacher-chapters/${id}`);
const getTeacherCourses = (id: any) => {
  return adminApi.get(`/get-teacher-courses/${id}`);
};

const duplicateChapter = (data: any) =>
  adminApi.post(`/duplicate-chapter`, data);

// full exam
const getFullExams = () => adminApi.get(`/full-exams`);
const getSingleFullExam = (id: number) => adminApi.get(`/full-exams/${id}`);
const createFullExam = (data: any) => adminApi.post("/full-exams", data);
const updateFullExam = (id: number, data: any) =>
  adminApi.post(`/full-exams/${id}`, data);
const deleteFullExam = (id: number) => adminApi.delete(`/full-exams/${id}`);

const createFullExamQuestion = (id: number, data: any) =>
  adminApi.post(`/full-exams/${id}/questions`, data);
const deleteFullExamQuestion = (id: number, questionId: number) =>
  adminApi.delete(`/full-exams/${id}/questions/${questionId}`);
const updateFullExamQuestionsOrder = (chapterId: number, data: any) =>
  adminApi.post(`/full-exams/${chapterId}/order-questions/`, data);

const getSingleFullExamQuestion = (id: number, idQuestion: number) =>
  adminApi.get(`/full-exams/${id}/questions/${idQuestion}`);
const updateFullExamQuestion = (id: number, idQuestion: number, data: any) =>
  adminApi.post(`/full-exams/${id}/questions/${idQuestion}`, data);

// Money
const getMoneyRequests = () => adminApi.get("/money-requests");
const getMoneyRequest = (id: number) => adminApi.get(`/money-requests/${id}`);
const createMoneyRequest = (data: any) =>
  adminApi.post("/money-requests", data);
const updateMoneyRequest = (id: number, data: any) =>
  adminApi.post(`/money-requests/${id}`, data);

// Finances
const getFinances = () => adminApi.get("/finances");
const getSingleBookSalesInfo = (id: number) =>
  adminApi.get(`/books/${id}/sales-info`);
const getSingleUserChargeWalletInfo = (id: number) =>
  adminApi.get(`/users/${id}/charge-wallet-info`);
const getSingleTeacherFinanceInfo = (id: number) =>
  adminApi.get(`/teachers/${id}/sales-info`);
const getSingleCourseFinanceInfo = (id: number) =>
  adminApi.get(`/courses/${id}/sales-info`);
const getSingleFullExamFinanceInfo = (id: number) =>
  adminApi.get(`/full-exams/${id}/sales-info`);

const getWallet = ({
  queryName,
  queryValue,
}: {
  queryName: string;
  queryValue: string;
}) => adminApi.get(`/home?${queryName}=${queryValue}`);

const getOrders = (page: number) =>
  adminApi.get(`/orders?${page ? `page=${page}&` : ""}`);
const getOrdersForExport = () => adminApi.get(`/export-orders`);

// comments

const getComments = (
  courseId: number,
  page: number,
  limit: number,
  replied: number,
  includeDeleted: boolean,
  review: number,
  user_id: number
) => {
  const queryParams = new URLSearchParams();

  if (page) queryParams.append("page", page.toString());
  if (limit) queryParams.append("limit", limit.toString());
  if (replied !== null && replied !== undefined)
    queryParams.append("replied", replied.toString());
  if (includeDeleted) queryParams.append("deleted", "1");
  if (review !== null && review !== undefined)
    queryParams.append("review", review.toString());
  courseId && queryParams.append("course_id", courseId.toString());
  if (user_id) queryParams.append("student_id", user_id.toString());
  const queryString = queryParams.toString();
  const url = `/comments${queryString ? `?${queryString}` : ""}`;

  return adminApi.get(url);
};

const addComment = (data: any) => adminApi.post("/comments", data);
const updateComment = (commentId: number, data: any) =>
  adminApi.post(`/comments/${commentId}`, data);
const deleteComment = (commentId: number) =>
  adminApi.delete(`/comments/${commentId}`);

// get course students
const getCourseUsers = (courseId: number, page: number, search: string) => {
  return adminApi.get(
    `/courses/${courseId}/students?${page ? `page=${page}&` : ""}${
      search ? `search=${search}&` : ""
    }`
  );
};

// get course lessons analytics
const getCourseLessonsAnalytics = (courseId: number) => {
  return adminApi.get(`/courses/${courseId}/lessons`);
};
// get course tests analytics
const getCourseTestsAnalytics = (courseId: number) => {
  return adminApi.get(`/courses/${courseId}/test`);
};

// get course lessons students analytics
const getCourseLessonsStudentsAnalytics = (
  courseId: number,
  lessonId: number,
  page: number,
  search: string
) => {
  return adminApi.get(
    `/courses/${courseId}/lessons/${lessonId}?&${page ? `page=${page}&` : ""}&${
      search ? `search=${search}&` : ""
    }`
  );
};

const getTeacherLessons = (chapter_id: number) => {
  return adminApi.get(`/chapters/${chapter_id}/lessons`);
};

const importLesson = (data: any) => {
  return adminApi.post("/lessons/duplicate", data);
};

// test bank
const getTestBank = (teacher_id?: number) =>
  adminApi.get(teacher_id ? `/banks?teacher_id=${teacher_id}` : "/banks");
const getSingleTestBank = (id: number) => adminApi.get(`/question-bank/${id}`);
const createTestBank = (data: any) => adminApi.post("/banks", data);
const updateTestBank = (id: number, data: any) =>
  adminApi.post(`/banks/${id}`, data);
const deleteTestBank = (id: number) => adminApi.delete(`/banks/${id}`);

// door test bank
const getDoorTestBank = (bank_id: number) =>
  adminApi.get(`/bank-doors?bank_id=${bank_id}`);
const getSingleDoorTestBank = (id: number) =>
  adminApi.get(`/question-bank/door/${id}`);
const createDoorTestBank = (data: any) => adminApi.post("/bank-doors", data);
const updateDoorTestBank = (id: number, data: any) =>
  adminApi.post(`/bank-doors/${id}`, data);
const deleteDoorTestBank = (id: number) => adminApi.delete(`/bank-doors/${id}`);

// lessons door
const getDoorLessons = (door_id: number) =>
  adminApi.get(`/bank-lessons?door_id=${door_id}`);
const getSingleDoorLessons = (id: number) =>
  adminApi.get(`/bank-lessons/${id}`);
const createDoorLessons = (data: any) => adminApi.post("/bank-lessons", data);
const updateDoorLessons = (id: number, data: any) =>
  adminApi.post(`/bank-lessons/${id}`, data);
const deleteDoorLessons = (id: number) =>
  adminApi.delete(`/bank-lessons/${id}`);

// levels lesson
const getLevelsLessons = (lesson_id: number) =>
  adminApi.get(`/bank-levels?lesson_id=${lesson_id}`);
const getSingleLevelsLessons = (id: number) =>
  adminApi.get(`/bank-levels/${id}`);
const createLevelsLessons = (data: any) => adminApi.post("/bank-levels", data);
const updateLevelsLessons = (id: number, data: any) =>
  adminApi.post(`/bank-levels/${id}`, data);
const deleteLevelsLessons = (id: number) =>
  adminApi.delete(`/bank-levels/${id}`);

// questions
const getQuestions = (lesson_id: number, level_id: number) =>
  adminApi.get(`/bank-questions?lesson_id=${lesson_id}&level_id=${level_id}`);
const getSingleQuestion = (id: number) => adminApi.get(`/bank-questions/${id}`);
const createQuestion = (data: any) => adminApi.post("/bank-questions", data);
const updateQuestion = (id: number, data: any) =>
  adminApi.post(`/bank-questions/${id}`, data);
const deleteQuestion = (id: number) => adminApi.delete(`/bank-questions/${id}`);

// student courses
const getStudentCourses = (id: number) =>
  adminApi.get(`/students/${id}/courses`);

const getUserSessions = (id: number) => adminApi.get(`/getusersessions/${id}`);

const changeStatus = (student_id: number, course_id: number, status: number) =>
  adminApi.post(
    `/students/change-status?student_id=${student_id}&course_id=${course_id}&status=${status}`
  );

// digital exams
const getDigitalExams = (teacher_id?: number) =>
  adminApi.get(
    `${
      teacher_id
        ? `/digital-lessons?teacher_id=${teacher_id}`
        : `/digital-lessons`
    }`
  );
const getSingleDigitalExams = (id: number) =>
  adminApi.get(`/digital-lessons/${id}`);
const createDigitalExams = (data: any) =>
  adminApi.post("/chapters/0/lessons", data);
const updateDigitalExams = (id: number, data: any) =>
  adminApi.post(`/digital-lessons/${id}`, data);
const deleteDigitalExams = (id: number) =>
  adminApi.delete(`/digital-lessons/${id}`);

// specific routes
const getTeacherBanks = (teacher_id: number) =>
  adminApi.get(`/teacher/bank?teacher_id=${teacher_id}`);
const getDoorsInBank = (bank_id: number) =>
  adminApi.get(`/teacher/bank/doors/${bank_id}`);
const getLessonsInDoor = (door_id: number) =>
  adminApi.get(`/teacher/bank/lessons/${door_id}`);
const getLevelsInLesson = (lesson_id: number) =>
  adminApi.get(`/teacher/bank/levels/${lesson_id}`);

const getTeaherSchoolYears = () => adminApi.get(`/get-teacher-school-years`);

const ADMINAPI = {
  getProfile,
  updateProfile,
  changePassword,
  getHome,
  getPermissions,
  getMyPermissions,
  getSubjects,
  getSingleSubject,
  createSubject,
  updateSubject,
  deleteSubject,
  getTeachers,
  getSingleTeacher,
  createTeacher,
  updateTeacher,
  deleteTeacher,
  updateTeachersOrder,
  getBooks,
  getSingleBook,
  createBook,
  updateBook,
  deleteBook,
  getExternalCodes,
  getExternalCode,
  createExternalCode,
  updateExternalCode,
  deleteExternalCode,
  getInternalCodes,
  getInternalCode,
  createInternalCode,
  updateInternalCode,
  deleteInternalCode,
  getModerators,
  getSingleModerator,
  createModerator,
  updateModerator,
  deleteModerator,
  getStudents,
  getSingleStudent,
  getStudentExams,
  correctQuestion,
  getUsers,
  getSingleUser,
  createUser,
  updateUser,
  updateUserStatus,
  deleteUser,
  adminLogin,
  getAllVideos,
  getTeacherVideos,
  getSingleVideo,
  createVideo,
  updateVideo,
  deleteVideo,
  getCourses,
  getSingleCourse,
  createCourse,
  updateCourse,
  deleteCourse,
  createCourseChapter,
  updateCourseChapter,
  deleteCourseChapter,
  createCourseLesson,
  deleteCourseLesson,
  updateLesson,
  updateLessonsOrder,
  getTeacherChapters,
  getTeacherCourses,
  duplicateChapter,
  // Money
  getMoneyRequests,
  getMoneyRequest,
  createMoneyRequest,
  updateMoneyRequest,
  // Finances
  getFinances,
  getSingleBookSalesInfo,
  getSingleUserChargeWalletInfo,
  getSingleTeacherFinanceInfo,
  getSingleCourseFinanceInfo,
  getSingleFullExamFinanceInfo,
  // full exams
  getFullExams,
  getSingleFullExam,
  createFullExam,
  updateFullExam,
  deleteFullExam,
  createFullExamQuestion,
  deleteFullExamQuestion,

  updateFullExamQuestion,
  getSingleFullExamQuestion,
  // Home month chart
  getWallet,
  updateFullExamQuestionsOrder,

  getOrders,
  getOrdersForExport,
  // comments
  getComments,
  addComment,
  updateComment,
  deleteComment,
  getCourseUsers,
  getCourseLessonsAnalytics,
  getCourseTestsAnalytics,
  getCourseLessonsStudentsAnalytics,
  getTeacherLessons,
  importLesson,
  // test bank
  getTestBank,
  getSingleTestBank,
  createTestBank,
  updateTestBank,
  deleteTestBank,
  // door test bank
  getDoorTestBank,
  getSingleDoorTestBank,
  createDoorTestBank,
  updateDoorTestBank,
  deleteDoorTestBank,

  // lessons door
  getDoorLessons,
  getSingleDoorLessons,
  createDoorLessons,
  updateDoorLessons,
  deleteDoorLessons,

  // levels lesson
  getLevelsLessons,
  getSingleLevelsLessons,
  createLevelsLessons,
  updateLevelsLessons,
  deleteLevelsLessons,
  //questions
  getQuestions,
  getSingleQuestion,
  createQuestion,
  updateQuestion,
  deleteQuestion,

  // student courses
  getStudentCourses,
  getUserSessions,

  changeStatus,

  // digital exams
  getDigitalExams,
  getSingleDigitalExams,
  createDigitalExams,
  updateDigitalExams,
  deleteDigitalExams,

  //specific routes
  getTeacherBanks,
  getDoorsInBank,
  getLessonsInDoor,
  getLevelsInLesson,

  getTeaherSchoolYears,
};

export default ADMINAPI;
